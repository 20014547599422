import React, { useState, useEffect } from 'react'
import Todo from './Todo';
import './Todo.css';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { supabase } from '../../backend/client';
import imgicono from './16x16.png';

    const TodoList = () => {
          const [taskName, setTaskname] = useState();
          const [fecha, setFecha ] = useState();
          const [dia, setDia ] = useState();
          const [telefono, setTelefono ] = useState();
          const notify = () => toast.success('reserved class!!!', {
            icon: '👏',
          });
                const saveRegister = async (e) => {
                  e.preventDefault();
                  try {
                      const user = supabase.auth.user()
                      const result = await supabase.from("tasks").insert({
                      name:taskName,
                      fecha: fecha,
                      dia: dia,
                      telefono: telefono,
                      userId: user.id
                      });
                      console.log(result);
                      }catch (error){
                        console.error(error);
                      }
                    } 
              return (
                
                <div className='card'>
                  <div className='form-main'>   
                    <div className='form-content'>
                      <div className='box'>                        
                        <img src={imgicono} className='img' />  
                      <h3>Welcome:</h3>
                        <h4>branch office: </h4>                   
                        <form onSubmit={ saveRegister }>   
                          <div className='input-box'>
                              <input type="date" className='input-control' 
                              onChange={(e) => setDia(e.target.value)}/>
                          </div>   
                            <div className='input-box'>
                            <select name='fecha' className='input-control select' onChange={(e) => setFecha(e.target.value)}>
                            <option value=":)">select your schedule</option>
                            <option value="9:00 am">9:00 am</option>
                            <option value="10:00 am">10:00 am</option>
                            <option value="11:00 am">11:00 am</option>
                            <option value="12:00 am">12:00 pm</option>
                            <option value="13:00 am">13:00 pm</option>
                            <option value="14:00 am">14:00 pm</option>
                            <option value="15:00 am">15:00 pm</option>
                            <option value="16:00 am">16:00 pm</option>
                            <option value="17:00 am">17:00 pm</option>
                            <option value="18:00 am">18:00 pm</option>
                            <option value="19:00 am">19:00 pm</option>
                          </select>
                            </div>
                            <div className='input-box'>
                              <input type="text" placeholder='Phone' className='input-control' name='telefono' onChange={(e) => setTelefono(e.target.value)}/>
                            </div>
                            <input type="submit" className='btn' onClick={notify}/>
                            <Toaster  toastOptions={{
                                // Define default options
                                className: '',
                                duration: 5000,
                                style: {
                                  background: '#074e91',
                                  color: '#E10000',
                                },
                                // Default options for specific types
                                success: {
                                  duration: 3000,
                                  theme: {
                                    primary: 'white',
                                    secondary: 'black',
                                  },
                                },
                              }}/>  
                        </form>                 
                      </div>
                    </div>
                  </div>
                </div>
              
              )
              
  }
export default TodoList;