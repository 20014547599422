import { useEffect, useState } from 'react';
import { supabase} from '../backend/client';
import styled from "styled-components";
import './styled.css'
import Icon from "./Icon";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


function Login () {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
        await supabase.auth.signIn({
            email,    
            password,        
        });
    } catch (error) {
        console.log(error);
     }
    };

    const FacebookBackground =
    "linear-gradient(to right, #0546A0 0%, #0546A0 40%, #663FB6 100%)";
    const InstagramBackground =
    "linear-gradient(to right, #A12AC4 0%, #ED586C 40%, #F0A853 100%)";
    const TwitterBackground =
    "linear-gradient(to right, #56C1E1 0%, #35A9CE 50%)";

    useEffect(() => {
        if (supabase.auth.user()){
            navigate("/");
        }
    }, [navigate]);
  return (
  <div className="Body">
          <div className="MainContainer">        
            <WelcomeText>Welcome</WelcomeText>
            <form onSubmit={handleSubmit}>             
                    <div className="InputContainer">
                      <input
                        type="email"
                        className="StyledInput"
                        placeholder="correo"
                        autoComplete="off" 
                        onChange={(e) => setEmail(e.target.value)}
                        />
                       <input
                        type="password"
                        className="StyledInput"
                        placeholder="password"
                        autoComplete="off" 
                        onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="ButtonContainer">
                        <button className="StyledButton">Sign Up</button>
                      </div>
                      <HorizontalRule></HorizontalRule>
                        <IconsContainer>
                      <Icon color={FacebookBackground}>
                        <FaFacebookF />
                      </Icon>
                      <Icon color={InstagramBackground}>
                        <FaInstagram />
                      </Icon>
                      <Icon color={TwitterBackground}>
                        <FaTwitter />
                      </Icon>
                    </IconsContainer>
                  </form>
              </div>
            </div>
  );
  }

const WelcomeText = styled.h2`
  margin: 3rem 0 2rem 0;
`;
const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  border: none;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;
const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;
export default Login