import React, { useEffect } from 'react'
import { supabase } from '../backend/client'
import { useNavigate } from 'react-router-dom'
import TodoList from '../componentes/TodoList/TodoList';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(!supabase.auth.user()){
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div>Home
    <button onClick={() => supabase.auth.signOut()}>
      Logout
    </button>
    <TodoList />
    </div>
  )
}

export default Home